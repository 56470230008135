import React, { Component } from "react";
import InputField from "../Helper/inputfield";
import Helper from "../Helper/Helper";
import api from "../../Environment";
import { withToastManager } from "react-toast-notifications";
import ToastDemo from "../Helper/toaster";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { apiConstants } from "../Constant/constants";
import { translate, t } from "react-multi-lang";
import configuration from "react-global-configuration";

import { gapi } from "gapi-script";
// import SocialButton from "../Helper/SocialButton";
const $ = window.$;
var const_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class Register extends Helper {
  state = {
    data: {
      name: "",
      email: "",
      password: "",
      device_type: "web",
      device_token: "123466",
      login_by: "manual",
      timezone: const_time_zone,
    },
    loadingContent: null,
    buttonDisable: false,
  };
  componentDidMount() {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId: configuration.get(
          "configData.social_logins.GOOGLE_CLIENT_ID"
        ),
        plugin_name: "chat",
      });
    });
  }

  responseFacebook = (response) => {
    const { path } = this.props.location;
    if (response) {
      if (response.status == "unknown") {
        ToastDemo(this.props.toastManager, "Cancelled", "error");
        return false;
      }
      const emailAddress =
        response.email === undefined || response.email === null
          ? response.id + "@facebook.com"
          : response.email;

      const facebookLoginInput = {
        social_unique_id: response.id,
        login_by: "facebook",
        email: emailAddress,
        name: response.name,
        device_type: "web",
        device_token: "123466",
        timezone: const_time_zone,
      };

      api
        .postMethod("register", facebookLoginInput)
        .then((response) => {
          if (response.data.success === true) {
            localStorage.setItem("userId", response.data.data.user_id);
            localStorage.setItem("accessToken", response.data.data.token);
            localStorage.setItem("userLoginStatus", true);
            localStorage.setItem("user_picture", response.data.data.picture);
            localStorage.setItem("username", response.data.data.username);

            // window.location = path ? path.from.pathname : "/home";
            ToastDemo(
              this.props.toastManager,
              response.data.message,
              "success"
            );
            this.setState({ loadingContent: null, buttonDisable: false });
            $("#login").modal("hide");
            if (path) {
              this.props.history.push(path.pathname);
            } else {
              this.props.history.push("/home");
            }
          } else {
            ToastDemo(
              this.props.toastManager,
              response.data.error_messages,
              "error"
            );
            this.setState({
              loadingContent: null,
              buttonDisable: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ loadingContent: null, buttonDisable: false });
        });
    }
  };

  responseGoogle = (response) => {
    const path = this.props.location;
    let googleData = response._profile;
    if (googleData == undefined || googleData == null || !googleData) {
      return ToastDemo(
        this.props.toastManager,
        "Google login failed.",
        "error"
      );
    }
    const googleLoginInput = {
      social_unique_id: googleData.id,
      login_by: "google",
      email: googleData.email,
      name: googleData.name,
      picture: googleData.profilePicURL,
      device_type: "web",
      device_token: "123466",
      timezone: const_time_zone,
    };
    api
      .postMethod("register", googleLoginInput)
      .then((response) => {
        if (response.data.success === true) {
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#signup").modal("hide");
          if (path) {
            this.props.history.push(path.pathname);
          } else {
            this.props.history.push("/home");
          }
        } else {
          this.setState({ loadingContent: null, buttonDisable: false });
          ToastDemo(this.props.toastManager, response.data.error, "error");
        }
      })
      .catch((error) => {
        this.setState({ loadingContent: null, buttonDisable: false });
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const path = this.props.location;
    this.setState({
      loadingContent: "Loading... Please wait..",
      buttonDisable: true,
    });
    api
      .postMethod("register", this.state.data)
      .then((response) => {
        if (response.data.success === true) {
          ToastDemo(this.props.toastManager, response.data.message, "success");
          this.setState({ loadingContent: null, buttonDisable: false });
          $("#signup").modal("hide");
          $("#login").modal("show");

          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("accessToken", response.data.data.token);
          localStorage.setItem("userLoginStatus", true);
          localStorage.setItem("user_picture", response.data.data.picture);
          localStorage.setItem("username", response.data.data.username);
          // window.location = path ? path.from.pathname : "/home";

          // if (path) {
          //   this.props.history.push(path.pathname);
          // } else {
          //   this.props.history.push("/home");
          // }
          // window.locatiom = path
          //   ? this.props.history.push(path.from.pathname)
          //   : this.props.history.push("/home");
          // this.props.history.push("/home");
        } else {
          ToastDemo(this.props.toastManager, response.data.error, "error");
          // $("#signup").modal("hide");
          this.setState({ loadingContent: null, buttonDisable: false });
        }
      })
      .catch(function (error) {});
  };

  render() {
    const { data } = this.state;
    return (
      <div>
        <div className="modal fade auth" id="signup">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  id="close-signup"
                  data-dismiss="modal"
                >
                  <i className="material-icons">close</i>
                </button>
              </div>

              <div className="modal-body" onSubmit={this.handleSubmit}>
                <h1 className="section-head">
                  {t("welcome_to_site", {
                    siteName: configuration.get("configData.site_name"),
                  })}
                </h1>
                <form className="top1">
                  <InputField
                    type="text"
                    name="name"
                    onChange={this.handleChange}
                    placeholder="name"
                    iconClassName="fas fa-lock"
                    value={data.name}
                  />

                  <InputField
                    type="text"
                    name="email"
                    onChange={this.handleChange}
                    placeholder="email"
                    iconClassName="fas fa-envelope"
                    value={data.email}
                  />

                  <InputField
                    type="password"
                    name="password"
                    onChange={this.handleChange}
                    placeholder="password"
                    iconClassName="fas fa-lock"
                    value={data.password}
                  />

                  <div className="align-centre">
                    <button
                      className="pink-btn"
                      disabled={this.state.buttonDisable}
                    >
                      {this.state.loadingContent != null
                        ? this.state.loadingContent
                        : "Signup"}
                    </button>
                  </div>
                </form>

                <div className="login-separator">{t("or_continue_with")}</div>
                <div className="row">
                  {configuration.get(
                    "configData.social_logins.GOOGLE_CLIENT_ID"
                  ) ? (
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 bottom1 margin-lr-auto">
                      {/* <SocialButton
                        provider="google"
                        key={"google"}
                        appId={configuration.get(
                          "configData.social_logins.GOOGLE_CLIENT_ID"
                        )}
                        onLoginSuccess={this.responseGoogle}
                        onLoginFailure={this.responseGoogle}
                        className="social-btn"
                        id="google-connect"
                      >
                        <i className="fab fa-google" /> {t("login_with_google")}
                      </SocialButton> */}
                      {/* <GoogleLogin
                        clientId={configuration.get(
                          "configData.social_logins.GOOGLE_CLIENT_ID"
                        )}
                        render={(renderProps) => (
                          <button
                            className="social-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <i className="fab fa-google" />{" "}
                            {t("login_with_google")}
                          </button>
                        )}
                        buttonText="Login"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {configuration.get(
                    "configData.social_logins.FB_CLIENT_ID"
                  ) ? (
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 bottom1 margin-lr-auto">
                      <FacebookLogin
                        appId={configuration.get(
                          "configData.social_logins.FB_CLIENT_ID"
                        )}
                        fields="name,email,picture"
                        scope="public_profile"
                        callback={this.responseFacebook}
                        render={(renderProps) => (
                          <button
                            className="social-btn"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <i className="fab fa-facebook fb social-icons" />{" "}
                            {t("login_with_facebook")}
                          </button>
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <p className="line" />
                <h4 className="m-0 text-center captalize">
                  {t("already_have_an_account", {
                    siteName: configuration.get("configData.site_name"),
                  })}{" "}
                  <a
                    href="#"
                    className="bold-cls close-signup"
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#login"
                  >
                    {" "}
                    {t("login")}
                  </a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withToastManager(Register);
